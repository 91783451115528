import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import Link from "next/link";
import Alreadymeber from "../../components/AlreadyMember";
import Logo from "../../components/Logo/Logo";
import PageTitle from "../../components/PageTitle";
import { checkSignUpCompleteAndRedirect } from "../../modules/auth/onBoard"
import { authStateUserData } from "../../modules/auth";
import { Loader } from "../../components/Toast";
import { Routes } from '../../config/constants';

const LandingPage = () => {
  const router = useRouter();
  const [loader, setLoader] = useState({ message: "Loading...", type: "loading" });
  const [alreadyLogined, setAlreadyLogined] = useState();

  useEffect(() => {
    router.prefetch(Routes.LOGIN)
    router.prefetch(Routes.JOINS_US)

    authStateUserData().then(({ user: currentUser }) => {
      if (currentUser) {
        setAlreadyLogined(currentUser)
        router.push(Routes.DASHBOARD);
        checkSignUpCompleteAndRedirect(router)
      } else {
        setLoader({ message: "", type: "" });
      }
    }).catch(() => {
      setLoader({ message: "", type: "" });
    })
  }, [])

  return (
    <>
      <PageTitle>Welcome</PageTitle>
      {(!alreadyLogined)&&<div className="landing-wrp gradientBg">
        <div className="landingWrap">
          <Logo />
          <h5>DesiWeds lets you plan your wedding, your way</h5>
          <p>
          Save time, access culture-specific resources, and host a wedding that reflects your personality with online management tools and a curated vendor-matching app.
          </p>
        </div>

        <div className="loginBtn-wrp">
          <Link href={Routes.JOINS_US}>
            <button className="bgBtn">Join Us</button>
          </Link>
          <Alreadymeber />
        </div>
      </div>}
      {loader.type && <Loader type={loader.type} message={loader.message} />}
    </>
  );
};

export default LandingPage;
