import { useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import msgs from "./PromiseResponses.json"

import 'react-toastify/dist/ReactToastify.css';

/**
 *
 * @param {Text} type : either info, success, warning, error, loading
 * @param {Text} message : message to be displayed
 * @param {Boolean} autoClose : In ms (default 3000)
 * @returns React Component
 */

export const Loader = ({ type = "", message = "", autoClose = 3000 }) => {

    type = type?.toUpperCase();
    const toastId = useRef(null);
    if (type) {
        if (type === "LOADING" && !(toastId.current)) {
            toastId.current = toast.loading(message);
        } else if (toast.TYPE[type] && toast.isActive(toastId.current)) {
            toast.update(toastId.current, { type: toast.TYPE[type], render: message, autoClose, isLoading: false });
            toastId.current = null
        }
    }
    return (
        <>
            <ToastContainer theme="colored" />
        </>
    )
}

export const Toast = ({ type, message, autoClose = 3000 }) => {
    if (type) {
        type = type.toUpperCase();
        if (toast.TYPE[type]) {
            toast[type.toLowerCase()](message, { autoClose });
        }
    }
    return (
        <>
            <ToastContainer theme="colored" />
        </>
    )
}


export const Progress = ({ progress, status = 'done', type }) => {
    const toastId = useRef(null);
    let { pending } = msgs[type];

    if (progress >= 0 && toastId === 'demmy') {
        progress = progress / 100
        if (status === 'done') {
            toast.done(toastId.current);
            setTimeout(() => {
                toastId.current = null;
            }, 500);
        } else if (status === 'pending' && !toast.isActive(toastId.current)) {
            toastId.current = toast(pending, { progress });
        } else {
            toast.update(toastId.current, { progress });
        }

    }
    return (
        <>
            <ToastContainer />
        </>
    )
}

export const HandlePromise = ({ promise, type, autoClose = 3000, message = {} }) => {
    let { pending, success, error } = msgs[type] || {};
    const toastId = useRef(null);
    useEffect(() => {
        toastId.current = randomString()
    }, [])
    if (promise) {
        toast.promise(promise, {
            pending: pending || message.pending || "Loading...",
            success: success || message.success || "Success",
            error: error || message.error || "Something went wrong"
        }, { autoClose, toastId: toastId.current });

    }
    return (
        <>
            <ToastContainer theme="colored" />
        </>
    )
}

const randomString = () => new Date().getTime().toString(36);
