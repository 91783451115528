import Link from "next/link";
import { Routes } from '../../config/constants';

export const Alreadymeber = () => {
    return (
        <Link href={Routes.LOGIN}>
            <h6>Already a member? <a>Sign In</a></h6>
        </Link>
    )
}

export default Alreadymeber;